import { Middleware } from 'one-ring';

const overrideMapping = [
    Middleware.CreateMapping('T_RanchBypassOverrideId', 'T_RanchBypassOverrideId'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('EndDate', 'EndDate'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('RanchName', 'RanchName'),
    Middleware.CreateMapping('StartDate', 'StartDate'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

const producingAreaMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('override', 'GET')) {
        Middleware.RegisterMapping('override', 'GET', overrideMapping);
        Middleware.RegisterMapping('override', 'POST', overrideMapping);
    }
    if (!Middleware.CheckMappingExists('producingArea', 'GET')) {
        Middleware.RegisterMapping('producingArea', 'GET', producingAreaMapping);
        Middleware.RegisterMapping('producingArea', 'POST', producingAreaMapping);
    }
}

const overrideMappingTypesGroup = {
    ConfigureMiddleWare
};

export default overrideMappingTypesGroup;
