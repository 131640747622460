import { Data } from 'react-data-grid-addons';

const handleFilterChange = (data, filters) => {
    const selectors = Data.Selectors;
    const getRows = (rows, filters) => {
        return selectors.getRows({ rows, filters });
    };

    const filteredRows = getRows(data, filters);
    return filteredRows;
};

export { handleFilterChange };
