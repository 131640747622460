import React, { Component } from 'react';
import { DrcGrid, DrcTooltip, DrcIcons } from 'driscolls-react-components';
import { Toolbar } from 'react-data-grid-addons';
import withStyles from '@material-ui/styles/withStyles';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { handleFilterChange } from '../../util/filter';
import { lookUpValues, resetSelectedIndex, enableFirstRowSelection } from '../../actions/PointMatrixAction';
import {
    DISTRICT_GROUP,
    ESTIMATE_GROUP,
    LABEL,
    BERRY_TYPE,
    PRODUCT_ATTRIBUTE,
    POOL_WEEK,
    MIN_POINTS,
    MAX_POINTS,
    SPREAD,
    VARIETY_DESCRIPTION,
    STATUS,
    MODIFIED_BY,
    MODIFIED_DATE_TIME
} from '../../language/english';
import { DuDateUtilities } from 'driscolls-react-utilities';

const styles = (theme) => ({
    main: {
        margin: 0
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-HeaderCell': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        },
        '& .react-grid-HeaderCell > div': {
            color: 'white'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        }
    }
});

const allBerries = (berryList) => (
    <>
        {(berryList === 'BLACK' || berryList === 1) && (
            <DrcTooltip tipText="BLACK">
                <span style={!(berryList.includes('BLACK') || berryList.includes(1)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLACK')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'BLUE' || berryList === 2) && (
            <DrcTooltip tipText="BLUE">
                <span style={!(berryList.includes('BLUE') || berryList.includes(2)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('BLUE')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'RASP' || berryList === 3) && (
            <DrcTooltip tipText="RASP">
                <span style={!(berryList.includes('RASP') || berryList.includes(3)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('RASP')}
                </span>
            </DrcTooltip>
        )}
        {(berryList === 'STRAW' || berryList === 4) && (
            <DrcTooltip tipText="STRAW">
                <span style={!(berryList.includes('STRAW') || berryList.includes(4)) ? { opacity: '0.1' } : null}>
                    {DrcIcons.GetSmallBerryIcon('STRAW')}
                </span>
            </DrcTooltip>
        )}
    </>
);

const BerryFormatter = ({ value, row }) => {
    return value ? allBerries(value) : null;
};

const cellFormatter = ({ value, row }) => {
    let column = getColumn(value, row);
    if (column === 'Status') {
        return (
            <span style={{ fontWeight: '500', color: value === 'Active' ? '#3ab517' : '#f93d22', fontSize: '13px' }} title={value}>
                {value}
            </span>
        );
    } else if (column === 'CreatedBy' || column === 'ModifiedBy') {
        return (
            <span>
                <a style={{ fontWeight: '500', fontSize: '13px', color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        );
    } else if (column === 'CreatedDatetime' || column === 'ModifiedDatetime') {
        return (
            <span style={{ fontWeight: '500', fontSize: '13px' }} title={DuDateUtilities.FormatDateTimeFromIso(value)}>
                {DuDateUtilities.FormatDateTimeFromIso(value)}
            </span>
        );
    }
    return (
        <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
            {value}
        </span>
    );
};

const getColumn = (value, row) => {
    for (let key in row) {
        if (row[key] === value) {
            return key;
        }
    }
};

const columns = [
    {
        key: 'QMPDistrictGroup',
        name: (
            <DrcTooltip tipText="District Group">
                <span>{DISTRICT_GROUP}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'Label',
        name: (
            <DrcTooltip tipText="Label">
                <span>{LABEL}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'CommodityCode',
        name: (
            <DrcTooltip tipText="Berry Type">
                <span>{BERRY_TYPE}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: BerryFormatter
    },
    {
        key: 'EstimateGroup',
        name: (
            <DrcTooltip tipText="Estimate Group">
                <span>{ESTIMATE_GROUP}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'AttributeType',
        name: (
            <DrcTooltip tipText="Product Attribute">
                <span>{PRODUCT_ATTRIBUTE}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'Status',
        name: (
            <DrcTooltip tipText="Status">
                <span>{STATUS}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'VarietyDescription',
        name: (
            <DrcTooltip tipText="Variety Description">
                <span>{VARIETY_DESCRIPTION}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'PoolWeek',
        name: (
            <DrcTooltip tipText="PoolWeek">
                <span>{POOL_WEEK}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'MinPoint',
        name: (
            <DrcTooltip tipText="Min Points">
                <span>{MIN_POINTS}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'MaxPoint',
        name: (
            <DrcTooltip tipText="Max Points">
                <span>{MAX_POINTS}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'Spread',
        name: (
            <DrcTooltip tipText="Spread">
                <span>{SPREAD}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'CreatedBy',
        name: (
            <DrcTooltip tipText={MODIFIED_BY}>
                <span>{MODIFIED_BY}</span>
            </DrcTooltip>
        ),
        width: 180,
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'CreatedDatetime',
        name: (
            <DrcTooltip tipText={MODIFIED_DATE_TIME}>
                <span>{MODIFIED_DATE_TIME}</span>
            </DrcTooltip>
        ),
        width: 180,
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    }
];

class PointMatrixTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndexes: '',
            oFilters: {},
            showPreviewDialog: false
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.RowRenderer = this.RowRenderer.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidUpdate() {
        if (this.props.isResetSelectedIndex) {
            this.setState({ selectedIndexes: '' });
            this.props.resetSelectedIndex(false);
        }
    }
    handleFilterChange = (filter) => {
        var filters = this.state.oFilters;
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.setState({ oFilters: newFilters });
    };

    onRowsSelected = (rows) => {
        console.log(rows);
        this.props.onRowSelected(rows);
        this.setState({
            selectedIndexes: rows[0]
        });
        this.props.enableFirstRowSelection(false);
        this.props.lookUpValues(rows[0].row.PointMatrixLookUpValues);
    };
    onRowClick = (rowId, row) => {
        if (rowId >= 0 && row) {
            this.onRowsSelected([{ row: row, rowIdx: rowId }]);
        }
    };
    RowRenderer = ({ renderBaseRow, ...props }) => {
        if (this.props.selectFirstRow) {
            if (props.idx === 0) {
                this.props.onRowSelected(props.row.PointMatrixLookUpValues);
                this.props.lookUpValues(props.row.PointMatrixLookUpValues);
            }
            return <div className={props.idx === 0 ? 'selected' : ''}>{renderBaseRow(props)}</div>;
        } else {
            return <div className={props.idx === this.state.selectedIndexes.rowIdx ? 'selected' : ''}>{renderBaseRow(props)}</div>;
        }
    };

    render() {
        const { classes, previewMatrixData } = this.props;
        return (
            <DrcGrid
                style={{ paddingBottom: 0 }}
                className={classes.grid}
                fullHeightOffset={10}
                rows={handleFilterChange(previewMatrixData, this.state.oFilters)}
                columns={columns}
                hideCount={true}
                enableRowSelect={true}
                onRowClick={this.onRowClick}
                rowRenderer={this.RowRenderer}
                rowSelection={{
                    showCheckbox: false,
                    multiSelect: false,
                    onRowsSelected: this.onRowsSelected,
                    selectBy: {
                        indexes: this.state.selectedIndexes
                    }
                }}
                toolbar={<Toolbar enableFilter={true} />}
                onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        previewMatrixData: state.pointMatrixReducer.previewMatrixData,
        isResetSelectedIndex: state.pointMatrixReducer.isResetSelectedIndex,
        selectFirstRow: state.pointMatrixReducer.selectFirstRow
    };
};

export default connect(mapStateToProps, { lookUpValues, resetSelectedIndex, enableFirstRowSelection })(
    withRouter(withAuth(withStyles(styles)(PointMatrixTable)))
);
