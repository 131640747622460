import { Middleware } from 'one-ring';

const labelMapping = [Middleware.CreateMapping('Descript', 'Descript'), Middleware.CreateArrayMapping('Results', 'Data')];

const berryTypeMapping = [
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('CommodityId', 'CommodityId'),
    Middleware.CreateMapping('CommodityCode', 'CommodityCode'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CommodityName', 'CommodityName'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const varietyMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

const pointMatrixMapping = [
    Middleware.CreateMapping('ProducingAreaCode', 'ProducingAreaCode'),
    Middleware.CreateMapping('AttributeType', 'AttributeType'),
    Middleware.CreateMapping('Label', 'Label'),
    Middleware.CreateMapping('CommodityCode', 'CommodityCode'),
    Middleware.CreateMapping('VarietyCode', 'VarietyCode'),
    Middleware.CreateMapping('PoolWeekStartDate', 'PoolWeekStartDate'),
    Middleware.CreateMapping('PoolWeekEndDate', 'PoolWeekEndDate'),
    Middleware.CreateMapping('MaxPoint', 'MaxPoint'),
    Middleware.CreateMapping('MinPoint', 'MinPoint'),
    Middleware.CreateMapping('Spread', 'Spread'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const pointMatrixLookupMapping = [
    Middleware.CreateMapping('PointMatrixLookupId', 'PointMatrixLookupId'),
    Middleware.CreateMapping('Score', 'Score'),
    Middleware.CreateMapping('Label', 'Label'),
    Middleware.CreateMapping('PointMatrixId', 'PointMatrixId'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDatetime', 'CreatedDatetime'),
    Middleware.CreateMapping('Point', 'Point'),
    Middleware.CreateMapping('MinScore', 'MinScore'),
    Middleware.CreateMapping('VarietyCode', 'VarietyCode'),
    Middleware.CreateMapping('ProducingAreaCode', 'ProducingAreaCode'),
    Middleware.CreateMapping('Label', 'Label'),
    Middleware.CreateMapping('CommodityCode', 'CommodityCode'),
    Middleware.CreateMapping('MaxScore', 'MaxScore'),
    Middleware.CreateMapping('MaxPoint', 'MaxPoint'),
    Middleware.CreateMapping('Spread', 'Spread'),
    Middleware.CreateMapping('ModifiedDatetime', 'ModifiedDatetime'),
    Middleware.CreateMapping('AttributeType', 'AttributeType'),
    Middleware.CreateMapping('MinPoint', 'MinPoint'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('label', 'GET')) {
        Middleware.RegisterMapping('label', 'GET', labelMapping);
        Middleware.RegisterMapping('label', 'POST', labelMapping);
    }
    if (!Middleware.CheckMappingExists('berryType', 'GET')) {
        Middleware.RegisterMapping('berryType', 'GET', berryTypeMapping);
        Middleware.RegisterMapping('berryType', 'POST', berryTypeMapping);
    }
    if (!Middleware.CheckMappingExists('variety', 'GET')) {
        Middleware.RegisterMapping('variety', 'GET', varietyMapping);
        Middleware.RegisterMapping('variety', 'POST', varietyMapping);
    }
    if (!Middleware.CheckMappingExists('pointMatrix', 'GET')) {
        Middleware.RegisterMapping('pointMatrix', 'GET', pointMatrixMapping);
        Middleware.RegisterMapping('pointMatrix', 'POST', pointMatrixMapping);
    }
    if (!Middleware.CheckMappingExists('Lookup', 'GET')) {
        Middleware.RegisterMapping('Lookup', 'GET', pointMatrixLookupMapping);
        Middleware.RegisterMapping('Lookup', 'POST', pointMatrixLookupMapping);
    }
}

const pointMatrixTypesGroup = {
    ConfigureMiddleWare
};

export default pointMatrixTypesGroup;
