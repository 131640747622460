import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReplayIcon from '@material-ui/icons/Replay';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { ImplicitCallback, Security } from '@okta/okta-react';
import {
    DrcBackdrop,
    DrcButton,
    DrcDialog,
    DrcEnvironmentMarker,
    DrcLoading,
    DrcMain,
    DrcPageNotFound,
    DrcPanel,
    DrcSecureGroupRoute,
    DrcSecureHeader,
    DrcThemeProvider,
    Helmet,
    DrcMediaQueries,
    DrcImage
} from 'driscolls-react-components';
import { DuAuthenticationUtilities, DuThemeUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { addInfo, hideErrorDialogAction, hideLoadingScreenAction, setErrorsAction, showLoadingScreenAction } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import LoggingUtilities from './data/LoggingUtilities';
import MasterDataUtilities from './data/MasterDataUtilities';
import LocationMain from './pages/Bypass/LocationMain';
import QuartileMain from './pages/Bypass/QuartileMain';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import InitializeApplication from './pages/InitializeApplication';
import LogOut from './pages/LogOut';
import NotificationMain from './pages/Notification/NotificationMain';
import ManagerMaintenanceMain from './pages/Maintenance/ManagerMaintenanceMain';
import OverrideMainPage from './pages/Override/OverrideMainPage';
import PointMatrixCreate from './pages/PointMatrix/PointMatrixCreate';
import PointMatrixPreview from './pages/PointMatrix/PointMatrixPreview';
const Releases = lazy(() => import('./pages/Releases'));

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const managerGroups = adminGroups.concat(window.config.OKTA_REGULAR_GROUPS || []);
const analystGroup = adminGroups.concat(window.config.OKTA_READ_ONLY_GROUPS || []);

const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super'));

const allLinks = [
    //{ title: 'Dashboard', url: '/Dashboard/', icon: <DashboardIcon /> },
    {
        title: 'Bypass',
        url: '/Bypass/Location/',
        icon: <ShuffleIcon />,
        subLinks: [
            { title: 'Location', url: '/Bypass/Location/' },
            { title: 'Quartile', url: '/Bypass/Quartile/' }
        ]
    },
    { title: 'Override', url: '/Override/', icon: <ReplayIcon /> },
    { title: 'Notifications', url: '/Notification/', icon: <NotificationsIcon /> },
    {
        title: 'Point Matrix',
        url: '/PointMatrix/Create/',
        icon: <SettingsIcon />,
        subLinks: [
            { title: 'Create', url: '/PointMatrix/Create/' },
            { title: 'Preview', url: '/PointMatrix/Preview/' }
        ]
    },
    {
        title: 'QA Manager',
        url: '/Maintenance/',
        icon: <AssignmentIndIcon />
    },
    {
        title: 'Releases',
        url: '/Releases/',
        icon: <EventIcon />,
        requiresGroups: superAdminGroups
    },
    { title: 'Sign Out', url: '/LogOut/', icon: <DirectionsRun /> }
];

let styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#6f5091',
            borderRadius: '5px'
        }
    },
    grid: {
        '& .gridCount': {
            margin: '-5px 10px 0 0'
        }
    },
    logo: {
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            backgroundColor: '#FFF',
            textShadow: `none`
        },
        '& .title': {
            textShadow: `none`,
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: `none`,
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: `absolute`,
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        }
    }
});

const CreateSimpleTheme = (lightPrimary, darkPrimary) => {
    return createMuiTheme(DuThemeUtilities.CreateSimpleThemeConfig(lightPrimary, darkPrimary));
};

const theme = CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.purple);

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const SITE_NAME = 'Quality Maintenance Platform';
const SITE_ACRONYM = 'QMP - ';
const LANDING_PAGE = '/Bypass/Location/';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.selectedMainTabIndex = 0;
        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : LANDING_PAGE);

        this.closeErrorDialog = this.closeErrorDialog.bind(this);
        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction, false);
    }

    closeErrorDialog() {
        this.props.hideErrorDialog();
    }

    render() {
        return (
            <DrcThemeProvider theme={theme}>
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? SITE_ACRONYM + this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security
                        issuer={window.config.OKTA_ISSUER}
                        client_id={window.config.OKTA_CLIENT_ID}
                        redirect_uri={window.location.origin + '/implicit/callback'}
                        onAuthRequired={DuAuthenticationUtilities.OnAuthRequired}
                        auto_renew={true}
                        scopes={['openid', 'email', 'MulesoftAPIAccess']}
                    >
                        <DrcSecureHeader
                            title={this.props.pageTitle.length > 0 ? SITE_ACRONYM + this.props.pageTitle : SITE_NAME}
                            allLinks={allLinks}
                            fullWidth={true}
                            logo={
                                <DrcImage
                                    src={require('./images/Logo_Small_Transparent.png')}
                                    webp={require('./images/Logo_Small_Transparent.webp')}
                                    className={this.props.classes.logo}
                                    alt="Driscoll's Logo"
                                />
                            }
                            className={this.props.classes.header}
                        />
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/implicit/callback" component={ImplicitCallback} />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Bypass/Location/" exact component={LocationMain} groupsAllowed={managerGroups} />
                                <DrcSecureGroupRoute path="/Bypass/Quartile/" exact component={QuartileMain} groupsAllowed={managerGroups} />
                                <DrcSecureGroupRoute path="/Override/" exact component={OverrideMainPage} groupsAllowed={managerGroups} />
                                <DrcSecureGroupRoute path="/Notification/" exact component={NotificationMain} groupsAllowed={managerGroups} />
                                <DrcSecureGroupRoute path="/PointMatrix/Create/" exact component={PointMatrixCreate} groupsAllowed={analystGroup} />
                                <DrcSecureGroupRoute path="/PointMatrix/Preview/" exact component={PointMatrixPreview} groupsAllowed={analystGroup} />
                                <DrcSecureGroupRoute path="/Maintenance/" exact component={ManagerMaintenanceMain} groupsAllowed={superAdminGroups} />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={superAdminGroups} />
                                <Route path="/LogOut/" exact render={(props) => <LogOut />} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <div
                        style={{
                            position: 'fixed',
                            bottom: '10px',
                            right: '10px'
                        }}
                    >
                        v. {process.env.REACT_APP_VERSION}
                    </div>
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingMessage} />
                    <DrcDialog
                        isError
                        title={this.props.errorDialog.title}
                        open={this.props.errorDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeErrorDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.errorDialog.content}
                    </DrcDialog>
                </Router>
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingMessage: state.rootReducer.loadingMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    showLoadingScreenAction: (title) => dispatch(showLoadingScreenAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
