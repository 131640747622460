import { UPDATE_QUARTILE_BYPASS, GET_QUARTILE_BYPASS, GET_VARIETY, ESTIMATED_GROUP, LOCATION_LIST } from '../Constant/ActionConstant';

const initialState = {
    quartileByPass: [],
    quartileByPassByLocation: [],
    variety: [],
    estimateGroup: [],
    location: []
};

const quartileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_QUARTILE_BYPASS:
            let quartileBypass = action.payload.map((item) => {
                if (item.Percentage === 'Q2') {
                    item.Percentage = 'Q1&Q2';
                }
                return item;
            });
            return { ...state, quartileByPass: quartileBypass, quartileByPassByLocation: quartileBypass };
        case UPDATE_QUARTILE_BYPASS:
            return { ...state, quartileByPass: action.payload };
        case GET_VARIETY:
            let variety = action.payload.map((item) => {
                return { value: item.Code, label: item.Code + '-' + item.Descript };
            });
            variety.splice(0, 0, { value: 'All', label: 'All' });
            return { ...state, variety };
        case ESTIMATED_GROUP:
            let estimateGroup = action.payload.map((item) => {
                return { value: item.EstimateGroup, label: item.EstimateGroup};
            });
            estimateGroup.splice(0, 0, { value: 'All', label: 'All' });
            return { ...state, estimateGroup };
        case LOCATION_LIST:
            let location = action.payload.map((item) => {
                return { value: item.Code, label: item.Code + '-' + item.Descript };
            });
            return { ...state, location };
        default:
            return state;
    }
};

export default quartileReducer;
