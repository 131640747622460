import React, { Component } from 'react';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

let styles = (theme) => ({
    root: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(0)
    },
    indicator: {
        backgroundColor: theme.palette.primary.main
    }
});

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(4),
        indicator: {
            backgroundColor: theme.palette.primary.main
        },
        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold
        }
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />);

class DrcTab extends Component {
    renderMenuItems = () => {
        const { menuItems, classes, selectedMenuIndex, ...rest } = this.props;

        return menuItems.map((item, index) => {
            return <StyledTab component={Link} key={index} label={item.label} disableRipple {...rest} />;
        });
    };

    /**
     * * Handles menu item change and passes selected index to parent component
     * @param value selected tab index
     */
    handleChange = (event, value) => {
        const { handleMenuItemChange, selectedMenuIndex } = this.props;
        if (selectedMenuIndex !== value) {
            handleMenuItemChange(value);
        }
    };

    render() {
        const { selectedMenuIndex, ...rest } = this.props;
        return (
            <Tabs value={selectedMenuIndex} onChange={this.handleChange} {...rest}>
                {this.renderMenuItems()}
            </Tabs>
        );
    }
}

export default withStyles(styles)(DrcTab);
