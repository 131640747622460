import { Middleware } from 'one-ring';

const notificationMapping = [
    Middleware.CreateMapping('NotifyId', 'NotifyId'),
    Middleware.CreateMapping('NotifyLocation', 'NotifyLocation'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('NotifyRanchId', 'NotifyRanchId'),
    Middleware.CreateMapping('NotifyRanchNbr', 'NotifyRanchNbr'),
    Middleware.CreateMapping('NotifyName', 'NotifyName'),
    Middleware.CreateMapping('NotifyEmailAddress', 'NotifyEmailAddress'),
    Middleware.CreateMapping('NotifyEmailActive', 'NotifyEmailActive'),
    Middleware.CreateMapping('IsNotifyOnReject', 'IsNotifyOnReject'),
    Middleware.CreateMapping('IsNotifyOnAlmostReject', 'IsNotifyOnAlmostReject'),
    Middleware.CreateMapping('AlmostRejectThreshold', 'AlmostRejectThreshold'),
    Middleware.CreateMapping('IsNotifyOnAll', 'IsNotifyOnAll'),
    Middleware.CreateMapping('IsEmailSpanish', 'IsEmailSpanish'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('notification', 'GET')) {
        Middleware.RegisterMapping('notification', 'GET', notificationMapping);
        Middleware.RegisterMapping('notification', 'POST', notificationMapping);
    }
}

const notificationMappingTypesGroup = {
    ConfigureMiddleWare
};

export default notificationMappingTypesGroup;
