import Parameters from './parameters';
class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static MASTER_DATA = '/MasterData/';
    static APPLICATION = '/Application/';

    static MASTER_DATA_APPLICATIONS = this.BASE + this.MASTER_DATA + 'Applications';
    static MASTER_DATA_APPLICATION_TYPES = this.BASE + this.MASTER_DATA + 'ApplicationTypes';
    static MASTER_DATA_APPLICATION_ASSOCIATIONS = this.BASE + this.MASTER_DATA + 'ApplicationAssociations';
    static MASTER_DATA_WORK_STATUSES = this.BASE + this.MASTER_DATA + 'WorkStatuses';
    static MASTER_DATA_WORK_TYPES = this.BASE + this.MASTER_DATA + 'WorkTypes';
    static MASTER_DATA_STATS = this.BASE + this.MASTER_DATA + 'Stats';
    static ALL_MASTER_DATA = this.BASE + this.MASTER_DATA + 'All';
    static GET_WAREHOUSES = this.BASE + 'master/warehouse';
    static OVERRIDE_DATA = this.BASE + 'ranch/override';
    static QUARTILE_LOCATION_DATA = '/staticdata/QuartileLocation.json';
    static POST_INSPECTION_BYPASS = this.BASE + 'inspectionbypass/berrytype';
    static POST_NOTIFICATION_DATA = this.BASE + 'notification';
    static GET_PRODUCING_AREA = this.BASE + 'master/producingarea';
    static GET_LABEL = this.BASE + 'master/label';
    static GET_BERRY_TYPE = this.BASE + 'master/berrytype';
    static POST_POINT_MATRIX = this.BASE + 'pointmatrix';
    static POST_PRODUCING_AREA = this.BASE + 'qamanager';
    static QUARTILE_ESTIMATED_GROUP = this.BASE + 'listestimategroup';
    static QUARTILE_BYPASS_DATA = '/staticdata/QuartileByPass.json';
    static GET_LOCAL_MANAGER_WAREHOUSES = this.BASE + 'managerwarehouses';
    static DISTRICT_GROUPS = this.BASE + 'listdistrictgroup';

    static GET_LOCAL_MANAGER_WAREHOUSES = this.BASE + 'managerwarehouses';

    static GET_ASSIGNED_PRODUCING_AREA = (producingArea) => {
        return `${this.BASE}/${Parameters.PRODUCING_AREA}=${producingArea}`;
    };

    static RANCH_DETAILS = (producingArea) => {
        return `${this.BASE}master/listranch?${Parameters.PRODUCING_AREA}=${producingArea}`;
    };
    //TODO: This is not needed, but is a good example for what we will need in the future.
    static FP_BY_CODE = (itemId) => {
        return `${this.FP_LINE_DETAILS}/${itemId}`;
    };

    static GET_WARE_HOUSE_BY_BERRY_TYPE = (berryType) => {
        return `${this.BASE}inspectionbypass/berrytype?${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static POST_WARE_HOUSE_LOCATION_BYPASS = `${this.BASE}inspectionbypass/berrytype`;

    static GET_BERRY_TYPE_BY_WAREHOUSE = (warehouse, berryType) => {
        return `${this.BASE}inspectionbypass/quartile?${Parameters.WAREHOUSENUMBER}=${warehouse}&${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static NOTIFICATION_DATA = (warehouse) => {
        return `${this.BASE}notification?${Parameters.WAREHOUSENUMBER}=${warehouse}`;
    };

    static GET_VARIETY = (berryType, producingArea) => {
        return `${this.BASE}master/variety?${Parameters.BERRY_TYPE}=${berryType}&${Parameters.PRODUCING_AREA}=${producingArea.split(",").join(`&${Parameters.PRODUCING_AREA}`)}`;
    };

    static GET_QUARTILE_VARIETY = (berryType, producingArea) => {
        return `${this.BASE}master/variety?${Parameters.BERRY_TYPE}=${berryType}`;
    };

    static GET_LOOKUP_DATA = (pointmatrixid) => {
        return `${this.BASE}pointmatrix?${Parameters.POINT_MATRIX_ID}=${pointmatrixid}`;
    };

    static GET_POINT_MATRIX_DATA = (
        districtGroup,
        estimateGroup,
        attributeType,
        label,
        poolweekstartdate,
        poolweekenddate,
        varietycode,
        berrytype,
        varietydescription,
        status,
    ) => {
        if (varietycode) {
            return `${this.BASE}pointmatrix?${Parameters.QMP_DISTRICT_GROUP}=${districtGroup}&${Parameters.ESTIMATE_GROUP}=${estimateGroup}&${Parameters.ATTRIBUTE_TYPE}=${attributeType}&${Parameters.LABEL}=${label}&${Parameters.POOL_WEEK_START_DATE}=${poolweekstartdate}&${Parameters.POOL_WEEK_END_DATE}=${poolweekenddate}&${Parameters.BERRY_TYPE}=${berrytype}&${Parameters.VARIETY_CODE}=${varietycode}&${Parameters.VARIETY_DESC}=${varietydescription}&${Parameters.STATUS_POINT_MATRIX}=${status}`;
        } else {
            return `${this.BASE}pointmatrix?${Parameters.QMP_DISTRICT_GROUP}=${districtGroup}&${Parameters.ESTIMATE_GROUP}=${estimateGroup}&${Parameters.ATTRIBUTE_TYPE}=${attributeType}&${Parameters.LABEL}=${label}&${Parameters.POOL_WEEK_START_DATE}=${poolweekstartdate}&${Parameters.POOL_WEEK_END_DATE}=${poolweekenddate}&${Parameters.BERRY_TYPE}=${berrytype}&${Parameters.STATUS_POINT_MATRIX}=${status}`;
        }
    };

    static GET_ASSIGNED_PRODUCING_AREAS = (producingAreaCode, email) => {
        if (producingAreaCode) {
            return `${this.BASE}qamanager?${Parameters.PRODUCING_AREA_CODE}=${producingAreaCode}`;
        } else {
            return `${this.BASE}qamanager`;
        }
    };

    static GET_USER_PRODUCING_AREAS = (email) => {
        return `${this.BASE}qamanager?${Parameters.EMAIL_ADDRESS}=${email}`;
    };
}

export default APIEndPoints;
