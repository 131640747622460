import { Middleware } from 'one-ring';

const MasterDataApiMappings = [
    Middleware.CreateMapping('Type', 'LookupType'),
    Middleware.CreateMapping('Value', 'LookupCode'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateSortMapping('Label', 'Meaning'),
    Middleware.CreateMapping('Order', 'DisplaySequence'),
    Middleware.CreateArrayMapping('Results')
];

const WarehouseMappings = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('LocalManager', 'LocalManager'),
    Middleware.CreateMapping('Description', 'Descript'),
    Middleware.CreateArrayMapping('Results','Data')
];

const BerryTypeMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Type', 'Type'),
    Middleware.CreateMapping('Value', 'Value'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('Label', 'Meaning'),
    Middleware.CreateMapping('Order', 'Order'),
    Middleware.CreateMapping('Tag', 'Tag'),
    Middleware.CreateMapping('LookupCode', 'LookupCode'),
    Middleware.CreateMapping('Enabled', 'Enabled'),
    Middleware.CreateMapping('CompanyId', 'CompanyId'),
    Middleware.CreateMapping('CustomerTerms', 'CustomerTerms'),
    Middleware.CreateMapping('EnabledFlag', 'EnabledFlag'),
    Middleware.CreateMapping('StartDateActive', 'StartDateActive'),
    Middleware.CreateMapping('lastUpdatedNameAndTime', 'lastUpdatedNameAndTime'),
    Middleware.CreateMapping('LastUpdatedBy', 'LastUpdatedBy'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('EndDateActive', 'EndDateActive'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('MasterDataApi', 'GET')) {
        Middleware.RegisterMapping('MasterDataApi', 'GET', MasterDataApiMappings, true);
        Middleware.RegisterMapping('MasterDataApi', 'POST', MasterDataApiMappings);
    }

    if (!Middleware.CheckMappingExists('warehouses', 'GET')) {
        Middleware.RegisterMapping('warehouses', 'GET', WarehouseMappings, true);
        Middleware.RegisterMapping('warehouses', 'POST', WarehouseMappings);
    }

    if (!Middleware.CheckMappingExists('berryType', 'GET')) {
        Middleware.RegisterMapping('berryType', 'GET', BerryTypeMappings);
        Middleware.RegisterMapping('berryType', 'POST', BerryTypeMappings);
    }
}

const MasterData = {
    ConfigureMiddleware
};

export default MasterData;
