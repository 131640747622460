import { MANAGER_MAINTENANCE_DATA } from '../Constant/ActionConstant';

const initialState = {
    managerMaintenanceGrid: []
};

const managerMaintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MANAGER_MAINTENANCE_DATA:
            return { ...state, managerMaintenanceGrid: action.payload };
        default:
            return state;
    }
};

export default managerMaintenanceReducer;
