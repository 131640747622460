import React, { Component } from 'react';
import { DrcGrid, DrcButton, DrcPanel } from 'driscolls-react-components';
import withStyles from '@material-ui/styles/withStyles';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { handleFilterChange } from '../../util/filter';
import { Middleware } from 'one-ring';
import APIEndPoints from '../../services/api';
import { CONFIRM } from '../../language/english';
import { lookUpValues, resetHeader } from '../../actions/PointMatrixAction';
import Grid from '@material-ui/core/Grid';
import { Toolbar } from 'react-data-grid-addons';

const styles = (theme) => ({
    main: {
        margin: 0
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    panel: {
        margin: 'auto',
        border: 0
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Header, & .react-grid-HeaderCell': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        },
        '& .react-grid-HeaderCell > div': {
            color: 'white'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        }
    }
});

const PAGE_TYPE = 'pointMatrix';

class PointMatrixScoreTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oFilters: {}
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.getData = this.getData.bind(this);
    }

    handleFilterChange = (filter) => {
        var filters = this.state.oFilters;
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.setState({ oFilters: newFilters });
    };

    getData = (pointMatrixId) => {
        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'Lookup',
                token,
                APIEndPoints.GET_LOOKUP_DATA(pointMatrixId),
                'GET',
                { Type: 'Lookup' },
                { overrideResponseMapping: true, loadingMessage: 'Fetching point matrix lookup...' }
            ).then((res) => {
                if (res && res.length) {
                    this.props.lookUpValues([]);
                    this.props.handleDisplayMessage('Point Matrix lookup created sucessfully', 'success');
                    this.props.resetHeader(true);
                }
            });
        });
    };

    handleConfirm = () => {
        const { lookupDataPayload } = this.props;
        lookupDataPayload.Operation = 'Create';
        this.props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_POINT_MATRIX, 'POST', lookupDataPayload, {
                overrideResponseMapping: true,
                loadingMessage: 'Creating point matrix lookup...'
            })
                .then((response) => {
                    if (response && response['PointMatrixId']) {
                        this.props.lookUpValues([]);
                        this.props.handleDisplayMessage('Point Matrix lookup created sucessfully', 'success');
                        this.props.resetHeader(true);
                    } else {
                        let message = response.message || 'Some thing happened. but I am not sure it is good or bad!';
                        this.props.handleDisplayMessage(message, 'error');
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Some thing happened. but I am not sure it is good or bad!';
                    this.props.handleDisplayMessage(message, 'error');
                });
        });
        console.log(this.props.lookupDataPayload);
    };
    render() {
        const { lookUp, columns, classes } = this.props;
        columns[0].width = 200;
        return (
            <>
                <Grid container spacing={3} justify="space-between" alignItems="flex-start">
                    <Grid style={{ padding: '0 12px' }} item xs={12} sm={12} md={12} lg={12}>
                        <DrcPanel ref="MaintenanceGrid" className={classes.panel} style={{ maxWidth: '40%' }}>
                            <DrcGrid
                                className={classes.grid}
                                minHeight={Math.max(window.innerHeight - 480, 180)}
                                rows={handleFilterChange(lookUp, this.state.oFilters)}
                                columns={columns}
                                hideCount={true}
                                toolbar={<Toolbar enableFilter={true} />}
                                onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
                            />
                            <DrcButton style={{ margin: '8px 0' }} className={classes.button} isPrimary floatRight onClick={this.handleConfirm}>
                                {CONFIRM}
                            </DrcButton>
                        </DrcPanel>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lookUp: state.pointMatrixReducer.lookUp
    };
};

export default connect(mapStateToProps, { lookUpValues, resetHeader })(withRouter(withAuth(withStyles(styles)(PointMatrixScoreTable))));
