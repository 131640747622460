class Parameters {
    static WAREHOUSENUMBER = 'warehousenbr';
    static RANCH_NUMBER = 'ranchNbr';
    static BERRY_TYPE = 'berrytype';
    static PRODUCING_AREA = 'producingarea';
    static POINT_MATRIX_ID = 'pointmatrixid';
    static PRODUCING_AREA_CODE = 'producingareacode';
    static ATTRIBUTE_TYPE = 'attributetype';
    static LABEL = 'label';
    static POOL_WEEK_START_DATE = 'poolweekstartdate';
    static POOL_WEEK_END_DATE = 'poolweekenddate';
    static VARIETY_CODE = 'varietycode';
    static BERRY_TYPE = 'berrytype';
    static VARIETY_DESC = 'varietydescription';
    static EMAIL_ADDRESS = 'emailaddress';
    static STATUS_POINT_MATRIX = 'status';
    static VARIETY = 'variety';
    static ESTIMATE_GROUP = 'estimategroup';
    static PERCENTAGE = 'percentage';
    static QMP_DISTRICT_GROUP = 'qmpdistrictgroup';
}

export default Parameters;
