import {
    RESET_UPDATE_QUARTILE_DATA,
    UPDATE_QUARTILE_BYPASS,
    SET_QUARTILE_BYPASS,
    GET_QUARTILE_BYPASS,
    GET_VARIETY,
    ESTIMATED_GROUP,
    LOCATION_LIST
} from '../Constant/ActionConstant';

export const resetUpdateQuartileData = (data) => ({
    type: RESET_UPDATE_QUARTILE_DATA,
    payload: data
});

export const setQuartileBypass = (data) => ({
    type: SET_QUARTILE_BYPASS,
    payload: data
});

export const getQuartileByPass = (data) => ({
    type: GET_QUARTILE_BYPASS,
    payload: data
});

export const updateQuartileBypass = (data, switchType) => ({
    type: UPDATE_QUARTILE_BYPASS,
    payload: data
});

export const getVariety = (data) => ({
    type: GET_VARIETY,
    payload: data
});

export const setEstimatedGroup = (data) => ({
    type: ESTIMATED_GROUP,
    payload: data
});

export const filterLocation = (data) => ({
    type: LOCATION_LIST,
    payload: data
});
