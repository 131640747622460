import { Middleware } from 'one-ring';

var allofpayload;
var currentIndex = 0;
var totalRecords = 0;
var offset = 100;
var data;
var payloads = [];
//if async then all the n* calls will be fired at once
var send = async (PAGE_TYPE, token, url, method, options, synchronous) => {
    let results = [];
    if (synchronous) {
        for (let i = 0; i < payloads.length; i++) {
            data = await Middleware.Send(PAGE_TYPE, token, url, method, payloads[i], options, synchronous);
            results.push(data);
        }
        return results;
    } else {
        results = await Promise.all(
            payloads.map(async (payloadSnippet, index) => {
                data = await Middleware.Send(PAGE_TYPE, token, url, method, payloadSnippet, options);
                return data;
            })
        );
        return results;
    }
};

var batchProcess = async (PAGE_TYPE, token, url, method, payload, options, synchronous = true) => {
    allofpayload = payload;
    totalRecords = payload.Inspections.length;
    currentIndex = 0;
    offset = 100;
    payloads = [];
    for (let i = 0; i < totalRecords; i += offset) {
        if (currentIndex < totalRecords) {
            offset = currentIndex + offset > totalRecords ? totalRecords : offset;
            let payloadSnippet = JSON.parse(JSON.stringify(allofpayload));
            payloadSnippet.Inspections = JSON.parse(JSON.stringify(allofpayload)).Inspections.splice(currentIndex, offset);
            payloads.push(payloadSnippet);
            currentIndex = currentIndex + offset;
        }
    }
    var data = await send(PAGE_TYPE, token, url, method, options, synchronous);
    return data && data.length > 0 ? data[0] : data;
};

const QuartileBatchProcess = {
    send: send,
    batchProcess: batchProcess
};

export default QuartileBatchProcess;
