import React from 'react';
import { DrcDialog, DrcButton } from 'driscolls-react-components';

const DirtyCheck = (props) => {
    return (
        <DrcDialog
            open={props.screenHasDataChanges}
            buttons={
                <React.Fragment>
                    <DrcButton isPrimary floatRight onClick={props.handleConfirmReject}>
                        Leave this page
                    </DrcButton>
                    <DrcButton isSecondary floatRight onClick={props.handleCancelNavigation}>
                        Stay on this page
                    </DrcButton>
                </React.Fragment>
            }
            title={`Unsaved changes`}
        >
            <p>You have unsaved changes that will be lost if you leave this page.</p>
            <p style={{ paddingBottom: '12px' }}>Are you sure you want to leave?</p>
        </DrcDialog>
    );
};

export default DirtyCheck;
