import { SET_NOTIFICATION_DATA } from '../Constant/ActionConstant.js';

const initialState = {
    notificationData: []
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_DATA:
            let NotificationPayload = action.payload;
            NotificationPayload.forEach((item) => {
                if (item.IsEmailSpanish === true) {
                    item.language = 'Spanish';
                } else {
                    item.language = 'English';
                }
                if (item.AlmostRejectThreshold === null) {
                    item.AlmostRejectThreshold = 0;
                }
                return item;
            });
            return { ...state, notificationData: NotificationPayload };    
        default:
            return state;
    }
};

export default notificationReducer;
