export const ManagerTabs = {
    mainTabs: [
        { label: 'BYPASS', to: '/Bypass/Location/' },
        { label: 'OVERRIDE', to: '/Override/' },
        { label: 'NOTIFICATIONS', to: '/Notification/' }
    ],
    ByPassTabs: {
        Bypass: [
            { label: 'Location', to: '/Bypass/Location/' },
            { label: 'Quartile', to: '/Bypass/Quartile/' }
        ]
    }
};

export const AnalystTabs = {
    mainTabs: [
        // { label: 'NOTIFICATIONS', to: '/Notification/' },
        { label: 'POINT MATRIX', to: '/PointMatrix/Create/' }
    ],
    pointMatrixTabs: {
        Matrix: [
            { label: 'Create', to: '/PointMatrix/Create/' },
            { label: 'Preview', to: '/PointMatrix/Preview/' }
        ]
    }
};

export const AdminTabs = {
    mainTabs: [
        { label: 'BYPASS', to: '/Bypass/Location/' },
        { label: 'OVERRIDE', to: '/Override/' },
        { label: 'NOTIFICATIONS', to: '/Notification/' },
        { label: 'POINT MATRIX', to: '/PointMatrix/Create/' },
        { label: 'QA MANAGER', to: '/Maintenance/' }
    ],
    pointMatrixTabs: {
        Matrix: [
            { label: 'Create', to: '/PointMatrix/Create/' },
            { label: 'Preview', to: '/PointMatrix/Preview/' }
        ]
    },
    ByPassTabs: {
        Bypass: [
            { label: 'Location', to: '/Bypass/Location/' },
            { label: 'Quartile', to: '/Bypass/Quartile/' }
        ]
    }
};
