import React from 'react';
import { DrcCheckbox } from 'driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

class LcCheckBox extends React.Component {
    onCheckChange = (event, selected) => {
        let id = this.props.name.split('_')[1];
        this.props.onSelectionChange(event, selected, id);
    };
    render() {
        return (
            <DrcCheckbox
                style={{color: this.props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#563e70' }}
                onChange={(event, selected) => {
                    this.onCheckChange(event, selected);
                }}
                {...this.props}
            />
        );
    }
}

export default withStyles(styles)(LcCheckBox);
