import {
    BERRY_TYPE,
    VARIETY,
    LABEL,
    LOOK_UP_VALUES,
    POINT_MATRIX_PREVIEW_DATA,
    IS_HEADER_RESET,
    RESET_SELECTED_INDEX,
    ENABLE_FIRST_ROW_SELECTION,
    ESTIMATED_GROUP,
    DISTRICT_GROUP
} from '../Constant/ActionConstant';

export const setBerryType = (data) => ({
    type: BERRY_TYPE,
    payload: data
});

export const setDistrictGroup = (data) => ({
    type: DISTRICT_GROUP,
    payload: data
});

export const setEstimatedGroup = (data) => ({
    type: ESTIMATED_GROUP,
    payload: data
});

export const setLabel = (data) => ({
    type: LABEL,
    payload: data
});

export const setVariety = (data) => ({
    type: VARIETY,
    payload: data
});

export const lookUpValues = (data) => ({
    type: LOOK_UP_VALUES,
    payload: data
});

export const getPointMatrixPreviewData = (data) => ({
    type: POINT_MATRIX_PREVIEW_DATA,
    payload: data
});

export const resetHeader = (data) => ({
    type: IS_HEADER_RESET,
    payload: data
});

export const resetSelectedIndex = (data) => ({
    type: RESET_SELECTED_INDEX,
    payload: data
});

export const enableFirstRowSelection = (data) => ({
    type: ENABLE_FIRST_ROW_SELECTION,
    payload: data
});
