import { Middleware } from 'one-ring';

const quartileMapping = [
    Middleware.CreateMapping('LocationId', 'LocationId'),
    Middleware.CreateMapping('Location', 'Location'),
    Middleware.CreateArrayMapping('Results')
];

const estimatedGroupMapping = [Middleware.CreateMapping('EstimatedGroup', 'EstimatedGroup'), Middleware.CreateArrayMapping('Results')];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('quartileLocation', 'GET')) {
        Middleware.RegisterMapping('quartileLocation', 'GET', quartileMapping);
        Middleware.RegisterMapping('quartileLocation', 'POST', quartileMapping);
    }
    if (!Middleware.CheckMappingExists('estimatedGroup', 'GET')) {
        Middleware.RegisterMapping('estimatedGroup', 'GET', estimatedGroupMapping);
        Middleware.RegisterMapping('estimatedGroup', 'POST', estimatedGroupMapping);
    }
}

const quartileMappingTypesGroup = {
    ConfigureMiddleWare
};

export default quartileMappingTypesGroup;
