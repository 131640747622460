import { DrcGrid } from 'driscolls-react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Toolbar } from 'react-data-grid-addons';

let styles = (theme) => ({
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-HeaderCell': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        },
        '& .react-grid-HeaderCell > div': {
            color: 'white'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        }
    }
});
class OverrideTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {}
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }
    handleFilterChange = (filter) => {
        var filters = this.state.filters;
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.props.onAddFilter(newFilters);
    };
    render() {
        const { rows, columns,classes, getCellActions } = this.props;
        return (
            <DrcGrid
                className={classes.grid}
                rows={rows}
                columns={columns}
                fullHeightOffset={10}
                hideCount={true}
                getCellActions={getCellActions}
                toolbar={<Toolbar enableFilter={true} />}
                onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
            />
        );
    }
}

export default connect(null, null)(withRouter(withAuth(withStyles(styles)(OverrideTable))));
