import { Middleware } from 'one-ring';

const ranchDetailsMapping = [
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('Name', 'Name'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('ranchDetails', 'GET')) {
        Middleware.RegisterMapping('ranchDetails', 'GET', ranchDetailsMapping);
        Middleware.RegisterMapping('ranchDetails', 'POST', ranchDetailsMapping);
    }
}

const ranchDetailsMappingTypesGroup = {
    ConfigureMiddleWare
};

export default ranchDetailsMappingTypesGroup;
