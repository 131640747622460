export const Status = [
    {
        value: 1,
        label: 'All'
    },
    {
        value: 2,
        label: 'Active'
    },
    {
        value: 3,
        label: 'Inactive'
    }
];
