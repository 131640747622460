export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_MASTER_DATA = 'SET_MASTER_DATA';
export const setMasterData = (data) => ({
    type: SET_MASTER_DATA,
    payload: data
});

export const WAREHOUSES = 'WAREHOUSES';
export const setWarehouses = (warehouses) => ({
    type: WAREHOUSES,
    payload: warehouses
});

export const BERRY_TYPES = 'BERRY_TYPES';
export const setBerryTypes = (berryTypes) => ({
    type: BERRY_TYPES,
    payload: berryTypes
});

export const SET_OVERRIDE_DATA = 'SET_OVERRIDE_DATA';
export const setOverrideData = (data) => ({
    type: SET_OVERRIDE_DATA,
    payload: data
});

export const SAVE_FETCHED_RANCH_DATA = 'SAVE_FETCHED_RANCH_DATA';
export const saveFetchedRanchData = (data) => ({
    type: SAVE_FETCHED_RANCH_DATA,
    payload: data
});

export const UPDATE_SAVE_ENABLED = 'UPDATE_SAVE_ENABLED';
export const updateSaveEnable = (data) => ({
    type: UPDATE_SAVE_ENABLED,
    payload: data
});

export const SELECT_DISPLAY_TABS = 'SELECT_DISPLAY_TABS';
export const selectDisplayTabs = (data) => ({
    type: SELECT_DISPLAY_TABS,
    payload: data
});

export const SET_USER_GROUP = 'SET_USER_GROUP';
export const setUserGroup = (data) => ({
    type: SET_USER_GROUP,
    payload: data
});

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const setLoggedInUser = (data) => ({
    type: SET_LOGGED_IN_USER,
    payload: data
});
