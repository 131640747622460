const handleRouting = (path) => {
    if (path === '/Main/' || path === '/Bypass/Location/') {
        return 'Bypass Location';
    } else if (path === '/Bypass/Quartile/') {
        return 'Bypass Quartile';
    } else if (path === '/Override/') {
        return 'Override';
    } else if (path === '/Notification/') {
        return 'Notification';
    } else if (path === '/PointMatrix/Create/') {
        return 'Point Matrix Create';
    } else if (path === '/PointMatrix/Preview/') {
        return 'Point Matrix Preview';
    } else if (path === '/Maintenance/') {
        return 'QA Manager';
    }
};

const subTabRouting = (path) => {
    if (path === '/PointMatrix/Create/') {
        return 'Point Matrix Create';
    } else if (path === '/PointMatrix/Preview/') {
        return 'Point Matrix Preview';
    }
};

const subTabByPassRouting = (path) => {
    if (path === '/Bypass/Location/') {
        return 'Bypass Location';
    } else if (path === '/Bypass/Quartile/') {
        return 'Bypass Quartile';
    }
};

const routingOnTabChange = (changingTabIndex, history) => {
    if (changingTabIndex === 0) {
        history.push('/Bypass/Location/');
        return 'Bypass Location';
    } else if (changingTabIndex === 1) {
        history.push('/Override/');
        return 'Override';
    } else if (changingTabIndex === 2) {
        history.push('/Notification/');
        return 'Notification';
    } else if (changingTabIndex === 3) {
        history.push('/PointMatrix/Create/');
        return 'Point Matrix Create';
    } else {
        history.push('/Maintenance/');
        return 'QA Manager';
    }
};
export { handleRouting, routingOnTabChange, subTabRouting, subTabByPassRouting };
