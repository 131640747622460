import { Middleware } from 'one-ring';

const quartileByPassMapping = [
    Middleware.CreateMapping('WarehouseNbr', 'WarehouseNbr'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('Variety', 'Variety'),
    Middleware.CreateMapping('EstimatedGroup', 'EstimatedGroup'),
    Middleware.CreateMapping('Quartile', 'Quartile'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('quartileByPass', 'GET')) {
        Middleware.RegisterMapping('quartileByPass', 'GET', quartileByPassMapping);
        Middleware.RegisterMapping('quartileByPass', 'POST', quartileByPassMapping);
    }
}

const quartileByPassMappingTypesGroup = {
    ConfigureMiddleWare
};

export default quartileByPassMappingTypesGroup;
