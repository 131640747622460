import {
    BERRY_TYPE,
    VARIETY,
    LABEL,
    LOOK_UP_VALUES,
    POINT_MATRIX_PREVIEW_DATA,
    IS_HEADER_RESET,
    RESET_SELECTED_INDEX,
    ENABLE_FIRST_ROW_SELECTION,
    DISTRICT_GROUP,
    ESTIMATED_GROUP,
} from '../Constant/ActionConstant';

const initialState = {
    berryType: [],
    variety: [{ value: 'Default', label: 'Default' }],
    label: [],
    previewVariety: [{ value: 'All', label: 'All' }],
    previewMatrixData: [],
    isResetHeader: false,
    isResetSelectedIndex: false,
    selectFirstRow: true,
    estimateGroup: []
};

const pointMatrixReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISTRICT_GROUP:
            let districtGroup = action.payload.map((item) => {
                return { value: item.ProducingAreaCode, label: item.QMPDistrictGroup };
            });
            return { ...state, districtGroup };

        case BERRY_TYPE:
            let berryType = action.payload.map((item) => {
                return { value: item.CommodityId, label: item.CommodityCode };
            });
            return { ...state, berryType };

        case LABEL:
            let label = action.payload.map((item) => {
                return { value: item.Descript, label: item.Descript };
            });
            return { ...state, label };

        case VARIETY:
            let obj = [{ value: 'Default', label: 'Default' }];
            let aVariety = action.payload.map((item) => {
                return { value: item.Code, label: item.Descript };
            });
            let variety = obj.concat(aVariety);
            let previewVariety = [{ value: 'All', label: 'All' }].concat(obj).concat(aVariety);
            return { ...state, variety, previewVariety };

        case LOOK_UP_VALUES:
            return { ...state, lookUp: action.payload };
        case ESTIMATED_GROUP:
            let estimateGroup = action.payload.map((item) => {
                return { value: item.EstimateGroup, label: item.EstimateGroup};
            });
            return { ...state, estimateGroup };

        case POINT_MATRIX_PREVIEW_DATA:
            let previewMatrixData = action.payload.map((item) => {
                if (item.PoolWeek === null) {
                    item.PoolWeek = 'Default';
                }
                if (item.VarietyDescription === null) {
                    item.VarietyDescription = 'Default';
                }
                return item;
            });
            return { ...state, previewMatrixData };

        case IS_HEADER_RESET:
            return { ...state, isResetHeader: action.payload };

        case RESET_SELECTED_INDEX:
            return { ...state, isResetSelectedIndex: action.payload };
        case ENABLE_FIRST_ROW_SELECTION:
            return { ...state, selectFirstRow: action.payload };
        default:
            return state;
    }
};

export default pointMatrixReducer;
