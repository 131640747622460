import Snackbar from '@material-ui/core/Snackbar';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/styles/withStyles';
import { withAuth } from '@okta/okta-react';
import { DrcButton, DrcGrid, DrcPanel, DrcTooltip } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { Data, Toolbar } from 'react-data-grid-addons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setOverrideData } from '../../actions/MasterActions';
import ConfirmDialog from '../../components/Override/ConfirmDialog';
import DeleteDialog from '../../components/Override/DeleteDialog';
import EditDialog from '../../components/Override/EditDialog';
import OverrideHeader from '../../components/Override/OverrideHeader';
import OverrideTable from '../../components/Override/OverrideTable';
import { END_DATE, RANCH_NAME, RANCH_NUMBER, START_DATE, MODIFIED_BY } from '../../language/english';
import APIEndPoints from '../../services/api';

const defaultColumnProperties = {
    resizable: true,
    filterable: true
};

let styles = (theme) => ({
    gridTitle: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#4a773c'
    },
    gridCount: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#6f5091;'
    },
    actionButton: {
        minWidth: '40px',
        display: 'block',
        margin: '0px !important',
        padding: '9px 8px 3px'
    },
    actionButtonError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    layout: {
        width: '97%'
    }
});

const getColumn = (value, row) => {
    for (let key in row) {
        if (row[key] === value) {
            return key;
        }
    }
};

const cellFormatter = ({ value, row }) => {
    let column = getColumn(value, row);
    if (column === 'NotifyEmailAddress') {
        return (
            <span>
                <a style={{ fontWeight: '500', fontSize: '13px', color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        );
    }
    return (
        <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
            {value}
        </span>
    );
};

const PAGE_TYPE = 'override';
const columns = [
    {
        key: 'RanchNbr',
        name: (
            <DrcTooltip tipText="Ranch Number">
                <span>{RANCH_NUMBER}</span>
            </DrcTooltip>
        ),
        isDisabled: true,
        inputType: 'input',
        filterable: true,
        resizable: true,
        width: 90,
        formatter: cellFormatter
    },
    {
        key: 'RanchName',
        name: (
            <DrcTooltip tipText="Ranch Name">
                <span>{RANCH_NAME}</span>
            </DrcTooltip>
        ),
        inputType: 'input',
        filterable: true,
        isDisabled: true,
        resizable: true,
        width: 350,
        formatter: cellFormatter
    },
    {
        key: 'startDateString',
        name: (
            <DrcTooltip tipText="Start Date">
                <span>{START_DATE}</span>
            </DrcTooltip>
        ),
        inputType: 'dateTime',
        filterable: true,
        validationType: 'required',
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'endDateString',
        name: (
            <DrcTooltip tipText="End Date">
                <span>{END_DATE}</span>
            </DrcTooltip>
        ),
        inputType: 'dateTime',
        filterable: true,
        validationType: 'required',
        resizable: true,
        formatter: cellFormatter
    },
    {
        key: 'ModifiedBy',
        name: (
            <DrcTooltip tipText="Modified By">
                <span>{MODIFIED_BY}</span>
            </DrcTooltip>
        ),
        filterable: true,
        resizable: true,
        formatter: cellFormatter
    }
];

const Override = (props) => {
    const [filters, setFilters] = useState({});
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });

    const [dialogDeleteContent, setDialogDeleteContent] = useState({
        dialogOpen: false,
        dialogText: '',
        dialogConfirm: () => {},
        oldValue: {},
        editedValue: {}
    });

    const [dialogEditContent, setDialogEditContent] = useState({
        dialogEditOpen: false,
        oldValue: {},
        editedValue: {},
        isEnabled: false,
        isEdit: false,
        helperText: []
    });

    const [confirmDialogContent, setConfirmDialogContent] = useState({
        dialogConfirmOpen: false,
        dialogText: '',
        oldValue: {},
        editedValue: {},
        helperText: []
    });

    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (defaultLoadingMessage) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.OVERRIDE_DATA,
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: defaultLoadingMessage ? 'Loading' : 'Fetching ranch override...' }
            ).then((data) => {
                props.setOverrideData(data.Data);
            });
        });
    };

    const changeHandler = () => {};

    const getCellActions = (column, row) => {
        var actions = [];
        actions.push({
            icon: (
                <DrcButton size="small" className={`${classes.actionButton} ${classes.actionButtonError}`}>
                    <DeleteForever />
                </DrcButton>
            ),
            callback: () => {
                setDialogDeleteContent({
                    dialogOpen: true,
                    dialogText: [
                        <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete Ranch Override?</div>,
                        <DrcGrid
                            onAddFilter={changeHandler}
                            onClearFilters={() => changeHandler()}
                            rows={[row]}
                            columns={columns}
                            minHeight={90}
                            hideCount={true}
                            toolbar={<Toolbar enableFilter={true} />}
                        />
                    ],
                    editedValue: { ...row }
                });
            }
        });
        actions.push({
            icon: (
                <DrcButton size="small" className={classes.actionButton}>
                    <EditIcon />
                </DrcButton>
            ),
            callback: () => {
                setDialogEditContent({
                    dialogEditOpen: true,
                    oldValue: { ...row },
                    editedValue: { ...row },
                    isEnabled: false,
                    isEdit: true,
                    helperText: []
                });
            }
        });
        const cellActions = { actions: actions }[column.key];
        return cellActions;
    };

    const updateOverrideData = (overrideData, status) => {
        console.log(overrideData);
        props.setOverrideData(overrideData);
    };

    const onSave = (status, severity, message, oldValue, newValue, helperText) => {
        switch (status) {
            case 'edit':
                setDialogEditContent({ dialogEditOpen: false });
                setConfirmDialogContent({
                    ...confirmDialogContent,
                    dialogConfirmOpen: true,
                    oldValue: oldValue,
                    editedValue: newValue,
                    helperText: helperText
                });
                break;
            case 'delete':
                setDialogDeleteContent({ dialogOpen: false });
                break;
            case 'confirm':
                setConfirmDialogContent({
                    dialogConfirmOpen: false
                });
                setMessageDialog({
                    showMessage: true,
                    severity: severity ? 'success' : 'error',
                    message: message
                });
                break;
            case 'cancel':
                setConfirmDialogContent({
                    dialogConfirmOpen: false
                });
                break;
            case 'close':
                setDialogEditContent({ dialogEditOpen: false });
                break;
            case 'deleteNo':
                setDialogDeleteContent({ dialogOpen: false });
                break;
            case 'deleteYes':
                setDialogDeleteContent({ dialogOpen: false });
                setMessageDialog({
                    showMessage: true,
                    severity: severity ? 'success' : 'error',
                    message: message
                });
                break;
            default:
                return;
        }
    };
    const handleFilterChange = (filters) => {
        setFilters(filters);
    };
    const handleClearFilter = () => {
        setFilters({});
    };

    const selectors = Data.Selectors;
    const getRows = (rows, filters) => {
        return selectors.getRows({ rows, filters });
    };

    const filteredRows = getRows(props.ranchOverrideData, filters);
    let countText;
    if (filteredRows.length > 1) {
        countText = `${filteredRows.length} records`;
    } else {
        countText = `${filteredRows.length} record`;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    let aGridColumns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));
    let actionWidth = 90;
    aGridColumns = [{ key: 'actions', name: 'Actions', width: actionWidth, frozen: true }, ...aGridColumns];

    const { classes } = props;
    return (
        <div>
            <DrcPanel
                style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
                className={props.classes.layout}
            >
                <DrcPanel style={{ maxWidth: '100%' }}>
                    <OverrideHeader getData={getData}></OverrideHeader>
                </DrcPanel>
                {props.ranchOverrideData && (
                    <div>
                        <h2 className={props.classes.gridTitle}>Ranch Override</h2>
                        <span className={props.classes.gridCount}>{countText}</span>
                        <OverrideTable
                            rows={filteredRows}
                            columns={aGridColumns}
                            getCellActions={getCellActions}
                            onAddFilter={handleFilterChange}
                            onClearFilters={handleClearFilter}
                        ></OverrideTable>
                    </div>
                )}
                {dialogDeleteContent.dialogOpen && (
                    <DeleteDialog
                        content={dialogDeleteContent}
                        overrideData={props.ranchOverrideData}
                        updateOverrideData={updateOverrideData}
                        onSave={onSave}
                    ></DeleteDialog>
                )}
                {dialogEditContent.dialogEditOpen && (
                    <EditDialog content={dialogEditContent} columns={columns} updateOverrideData={updateOverrideData} onSave={onSave}></EditDialog>
                )}
                {confirmDialogContent.dialogConfirmOpen && (
                    <ConfirmDialog
                        content={confirmDialogContent}
                        columns={columns}
                        overrideData={props.ranchOverrideData}
                        updateOverrideData={updateOverrideData}
                        onSave={onSave}
                    ></ConfirmDialog>
                )}
            </DrcPanel>
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ranchOverrideData: state.masterReducer.ranchOverrideData
    };
};

export default connect(mapStateToProps, { setOverrideData })(withRouter(withAuth(withStyles(styles)(Override))));
