import DataUtilities from '../data/DataUtilities';
import { DuDateUtilities } from 'driscolls-react-utilities';

const startDate = (event, overrideData) => {
    let isValid = true;
    let showErrorMessage = false;
    let helperText = '';
    let newDate = DataUtilities.ConvertToMilliSeconds(new Date(event));
    if (!newDate) {
        helperText = 'Enter valid date';
    }
    let newDateIso = DuDateUtilities.ToIsoDate(newDate);
    let currentDate = DataUtilities.ConvertToMilliSeconds(new Date());
    let EndDateConverted = DataUtilities.ConvertToMilliSeconds(overrideData.EndDate);
    if (overrideData.EndDate && EndDateConverted < newDate) {
        showErrorMessage = true;
        newDateIso = overrideData.startDateString ? overrideData.startDateString : overrideData.StartDate;
        let newDate = DataUtilities.ConvertToMilliSeconds(new Date(newDateIso));
        newDateIso = DuDateUtilities.ToIsoDate(newDate);
        isValid = false;
    }
    if (currentDate > newDate) {
        isValid = false;
        if (!overrideData.isEdit) {
            helperText = 'Enter valid date';
        }
    }
    return {
        StartDate: newDateIso,
        startDateString: DuDateUtilities.FormatDateFromIso(newDateIso),
        validStartDate: isValid,
        disableStartChanges: isValid,
        showErrorMessage: showErrorMessage,
        errorMessage: 'Expected Start Date cannot be greater than End Date.',
        helperText: helperText
    };
};

const endDate = (event, overrideData) => {
    let isValid = true;
    let showErrorMessage = false;
    let helperText = '';
    let newDate = DataUtilities.ConvertToMilliSeconds(new Date(event));
    if (!newDate) {
        helperText = 'Enter valid date';
    }
    let newDateIso = DuDateUtilities.ToIsoDate(newDate);
    let currentDate = DataUtilities.ConvertToMilliSeconds(new Date());
    let startDateConverted = DataUtilities.ConvertToMilliSeconds(overrideData.StartDate);
    if (overrideData.StartDate && startDateConverted > newDate) {
        showErrorMessage = true;
        newDateIso = overrideData.endDateString ? overrideData.endDateString : overrideData.EndDate;
        let newDate = DataUtilities.ConvertToMilliSeconds(new Date(newDateIso));
        newDateIso = DuDateUtilities.ToIsoDate(newDate);
        isValid = false;
    }
    if (currentDate > newDate) {
        isValid = false;
        if (!overrideData.isEdit) {
            helperText = 'Enter valid date';
        }
    }

    return {
        EndDate: newDateIso,
        endDateString: DuDateUtilities.FormatDateFromIso(newDateIso),
        validEndDate: isValid,
        disableEndChanges: isValid,
        showErrorMessage: showErrorMessage,
        errorMessage: 'End Date cannot be less than Start Date.',
        helperText: helperText
    };
};

const validateRanchNumber = (event) => {
    let val = event.target.value;
    let ASCIICode = val.charCodeAt(val.length - 1);
    if ((ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) || val.length > 7) {
        return false;
    } else {
        return true;
    }
};
export { startDate, endDate, validateRanchNumber };
