import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { DrcMain, DrcOktaWidget, DrcSignInVersionFooter, DrcImage } from 'driscolls-react-components';
import { setPageTitleAction } from '../actions/actions';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: null
        };

        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
        }
    }

    async componentDidMount() {
        this.props.setPageTitle('');
        this.checkAuthentication();
    }

    async componentDidUpdate() {
        this.checkAuthentication();
    }

    onSuccess(res) {
        if (res.status === 'SUCCESS') {
            return this.props.auth.redirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    }

    onError(err) {
        console.log('error logging in', err);
    }

    render() {
        const errorMessage = this.state.error ? <span className="error-message">{this.state.error}</span> : null;

        return this.state.authenticated ? (
            <Redirect to="/InitializeApplication/" />
        ) : (
            <React.Fragment>
                <DrcMain transparent>
                    <DrcImage
                        src={require('../images/Wood_Rasp_Full_Hd.png')}
                        webp={require('../images/Wood_Rasp_Full_Hd.webp')}
                        style={{
                            position: 'absolute',
                            height: '100vh',
                            width: '100vw',
                            top: 0,
                            left: 0,
                            objectFit: 'cover',
                            zIndex: -1
                        }}
                        alt=""
                    />
                    <DrcOktaWidget
                        baseUrl={window.config.OKTA_URL}
                        config={{ logo: '/Login_Logo.png' }}
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                    />
                    <DrcSignInVersionFooter errorMessage={errorMessage} />
                </DrcMain>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(null, mapDispatchToProps)(withAuth(Home));
