import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import DataUtilities from '../data/DataUtilities';
import { DuDateUtilities } from 'driscolls-react-utilities';

const getPayload = (editedValue) => {
    const timeStamp = new Date().toISOString();
    let loggedInUser;
    if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'));
        loggedInUser = user.email.split('@')[0];
    }
    let editPayload = {
        RanchNbr: editedValue.RanchNbr,
        RanchName: editedValue.RanchName,
        StartDate: editedValue.StartDate.split('T')[0],
        EndDate: editedValue.EndDate.split('T')[0],
        CreatedBy: loggedInUser,
        CreatedDateTime: timeStamp,
        ModifiedDateTime: timeStamp
    };
    return editPayload;
};

const getNotificationPayload = (editedValue, token, oldValue, location) => {
    const timeStamp = new Date().toISOString();
    if (editedValue.language === 'Spanish') {
        editedValue.IsEmailSpanish = true;
    } else {
        editedValue.IsEmailSpanish = false;
    }

    let berrySet = {};
    if (editedValue && editedValue.BerryType && editedValue.BerryType.length) {
        const newBerryType = editedValue.BerryType;
        for (let i = 0; i < newBerryType.length; i++) {
            if (!berrySet[newBerryType[i]]) {
                berrySet[newBerryType[i]] = false;
            }
        }
    }
    if (oldValue) {
        if (oldValue && oldValue.BerryType && oldValue.BerryType.length) {
            const oldBerryType = oldValue.BerryType;
            for (let i = 0; i < oldBerryType.length; i++) {
                if (berrySet[oldBerryType[i]] === undefined) {
                    berrySet[oldBerryType[i]] = true;
                }
            }
        }
    }

    const loggedInUser = DuAuthenticationUtilities.GetEmail(token);
    let payload = {
        NotifyId: editedValue.NotifyId,
        NotifyLocation: editedValue.NotifyLocation ? editedValue.NotifyLocation : location.label,
        NotifyName: editedValue.NotifyName,
        NotifyEmailAddress: editedValue.NotifyEmailAddress,
        NotifyEmailActive: editedValue.NotifyEmailActive,
        IsNotifyOnReject: editedValue.IsNotifyOnReject,
        IsNotifyOnAlmostReject: editedValue.IsNotifyOnAlmostReject,
        AlmostRejectThreshold: editedValue.AlmostRejectThreshold,
        IsNotifyOnAll: editedValue.IsNotifyOnAll,
        IsEmailSpanish: editedValue.IsEmailSpanish,
        WeeklyReport: editedValue.WeeklyReport,
        DailyReport: editedValue.DailyReport
    };

    let payloadCollection = [];
    let berryType = [];

    berryType = Object.keys(berrySet);

    for (let k = 0; k < berryType.length; k++) {
        let copiedPayload = { ...payload };
        copiedPayload.BerryType = berryType[k];
        copiedPayload.IsDeleted = berrySet[berryType[k]];
        payloadCollection.push(copiedPayload);
    }
    let finalPayload = {
        Notification: payloadCollection,
        LastUpdatedBy: loggedInUser,
        LastUpdatedDateTime: timeStamp
    };

    return finalPayload;
};

const getNotificationDeletePayload = (deletedValue, token) => {
    const timeStamp = new Date().toISOString();
    if (deletedValue.language === 'Spanish') {
        deletedValue.IsEmailSpanish = true;
    } else {
        deletedValue.IsEmailSpanish = false;
    }

    const loggedInUser = DuAuthenticationUtilities.GetEmail(token);
    let payload = {
        NotifyId: deletedValue.NotifyId,
        NotifyLocation: deletedValue.NotifyLocation,
        NotifyRanchId: deletedValue.NotifyRanchId,
        NotifyRanchNbr: deletedValue.NotifyRanchNbr,
        NotifyName: deletedValue.NotifyName,
        NotifyEmailAddress: deletedValue.NotifyEmailAddress,
        NotifyEmailActive: deletedValue.NotifyEmailActive,
        IsNotifyOnReject: deletedValue.IsNotifyOnReject,
        IsNotifyOnAlmostReject: deletedValue.IsNotifyOnAlmostReject,
        AlmostRejectThreshold: deletedValue.AlmostRejectThreshold,
        IsNotifyOnAll: deletedValue.IsNotifyOnAll,
        IsEmailSpanish: deletedValue.IsEmailSpanish,
        IsDeleted: true
    };

    let payloadCollection = [];

    const deletedBerryType = deletedValue.BerryType;
    for (let i = 0; i < deletedBerryType.length; i++) {
        let copiedPayload = { ...payload };
        copiedPayload.BerryType = deletedBerryType[i];
        payloadCollection.push(copiedPayload);
    }
    let finalPayload = {
        Notification: payloadCollection,
        LastUpdatedBy: loggedInUser,
        LastUpdatedDateTime: timeStamp
    };

    return finalPayload;
};

const getLoggedInUser = () => {
    let loggedInUser;
    if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'));
        loggedInUser = user.email.split('@')[0];
        return loggedInUser;
    } else {
        return;
    }
};

const getFormattedDateValues = (pointMatrix) => {
    let startDate, endDate, varietyCode, varietyDescription;
    varietyCode = pointMatrix.variety.value;
    varietyDescription = pointMatrix.variety.label;
    if (varietyCode === 'Default' || varietyCode === 'default') {
        varietyCode = 'default';
        varietyDescription = 'default';
    }
    if (pointMatrix.isDefault) {
        startDate = 'default';
        endDate = 'default';
    } else {
        let newStartDate = DataUtilities.ConvertToMilliSeconds(new Date(pointMatrix.startDate));
        startDate = DuDateUtilities.ToIsoDate(newStartDate).split('T')[0];
        let newEndDate = DataUtilities.ConvertToMilliSeconds(new Date(pointMatrix.endDate));
        endDate = DuDateUtilities.ToIsoDate(newEndDate).split('T')[0];
    }
    return {
        varietyCode: varietyCode,
        varietyDescription: varietyDescription,
        startDate: startDate,
        endDate: endDate
    };
};

const getPointMatrixPayload = (pointMatrix, token) => {
    const timeStamp = new Date().toISOString();
    const loggedInUser = DuAuthenticationUtilities.GetEmail(token);
    let oValues = getFormattedDateValues(pointMatrix);
    let payload = {
        DistrictGroup: pointMatrix.districtGroup.label,
        EstimateGroup: pointMatrix.estimatedGroup.label,
        AttributeType: 'Brix',
        Label: pointMatrix.label.value,
        CommodityCode: pointMatrix.berryType.label,
        VarietyCode: oValues.varietyCode,
        VarietyDescription: oValues.varietyDescription,
        PoolWeekStartDate: oValues.startDate,
        PoolWeekEndDate: oValues.endDate,
        MaxPoint: pointMatrix.maxPoint,
        MinPoint: pointMatrix.minPoint,
        Spread: pointMatrix.spread,
        ModifiedBy: loggedInUser,
        ModifiedDateTime: timeStamp
    };
    return payload;
};

const getManagerMaintenancePayload = (maintenance, producingArea, loggedInUser) => {
    let aMaintenance = maintenance.ProducingAreaCode.split('-')[0].trim();
    let producingAreaDesc = producingArea.filter((item) => aMaintenance === item.value);
    const timeStamp = new Date().toISOString();

    let payload = {
        LocalManagerId: null,
        ProducingAreaCode: aMaintenance,
        ProducingAreaDescription: producingAreaDesc[0].label.split('-')[1],
        LocalQAManagerEmailAddress: maintenance.LocalQAManagerEmailAddress,
        IsDeleted: false,
        CreatedBy: loggedInUser,
        CreatedDateTime: timeStamp,
        ModifiedBy: loggedInUser,
        ModifiedDateTime: timeStamp
    };
    return payload;
};

const getQuartilePayload = (quartileFilterData, percentage, loggedInUser) => {
    const timeStamp = new Date().toISOString();
    let payload = {
        Inspections: [
            {
                WarehouseNbr: quartileFilterData.location.value,
                BerryType: quartileFilterData.berryType.label,
                Variety: quartileFilterData.variety.value,
                EstimateGroup: quartileFilterData.estimatedGroup.value,
                Bypass: percentage === 'None' ? false : true,
                Percentage: percentage,
                OverallStatus: percentage === 'None' ? false : true
            }
        ],
        LastUpdatedBy: loggedInUser,
        LastUpdatedDateTime: timeStamp
    };
    return payload;
};

const quartileDialogGridData = (quartileFilterData, percentage, loggedInUser) => {
    if (percentage === 'Q2') {
        percentage = 'Q1&Q2';
    }
    const timeStamp = new Date().toISOString();
    let payload = {
        WarehouseNbr: quartileFilterData.location.value,
        BerryType: quartileFilterData.berryType.label,
        Variety: quartileFilterData.variety.value,
        EstimateGroup: quartileFilterData.estimatedGroup.value,
        Bypass: true,
        Percentage: percentage,
        LastUpdatedBy: loggedInUser,
        LastUpdatedDateTime: timeStamp
    };
    return payload;
};

export {
    getPayload,
    getLoggedInUser,
    getNotificationPayload,
    getNotificationDeletePayload,
    getFormattedDateValues,
    getPointMatrixPayload,
    getManagerMaintenancePayload,
    getQuartilePayload,
    quartileDialogGridData
};
