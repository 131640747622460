import { PRODUCING_AREA, RANCH_DETAILS, SAVED_RANCH_DETAILS, USER_PRODUCING_AREA } from '../Constant/ActionConstant';

export const setProducingAreaList = (data) => ({
    type: PRODUCING_AREA,
    payload: data
});

export const setRanchDetailsData = (producingArea, data) => ({
    type: RANCH_DETAILS,
    payload: {
        data,
        producingArea
    }
});

export const getSavedRanchDetails = (data) => ({
    type: SAVED_RANCH_DETAILS,
    payload: data
});

export const setUserProducingArea = (data) => ({
    type: USER_PRODUCING_AREA,
    payload: data
});
