import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED,
    SET_MASTER_DATA,
    WAREHOUSES,
    BERRY_TYPES,
    SET_OVERRIDE_DATA,
    SAVE_FETCHED_RANCH_DATA,
    UPDATE_SAVE_ENABLED,
    SELECT_DISPLAY_TABS,
    SET_USER_GROUP,
    SET_LOGGED_IN_USER
} from '../actions/MasterActions';
import { DuDateUtilities } from 'driscolls-react-utilities';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/Bypass/Location/';

const initialState = {
    isInitialized: false,
    redirectUrl: DEFAULT_REDIRECT_URL,
    ranchOverrideData: [],
    quartileLocation: [],
    fetchedRanchData: [],
    updateSaveAction: false,
    producingArea: [],
    tabs: {},
    userGroup: '',
    loggedInUser: '',
    index: 0
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }
            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);
            return { ...state, redirectUrl: action.payload };

        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case SET_MASTER_DATA:
            var data = action.payload;

            return Object.assign({}, state, {
                isInitialized: true,
                ...data
            });
        case WAREHOUSES:
            var warehouseOptions = action.payload.map((elm) => {
                return { value: elm.Code, label: elm.Code + ' - ' + elm.Description, type: 'warehouse' };
            });

            var quartileLocation = action.payload.map((elm) => {
                return { value: elm.Description, label: elm.Code };
            });

            var seenModes = [];

            warehouseOptions = warehouseOptions.filter((item) => {
                var isSeen = seenModes.includes(item.label);

                if (!isSeen) {
                    seenModes.push(item.label);
                    return true;
                }

                return false;
            });

            return Object.assign({}, state, {
                warehouses: action.payload,
                warehouseOptions,
                quartileLocation
            });
        case BERRY_TYPES:
            var berryTypeOptions = action.payload.map((status) => {
                return { value: (status.Value || '').trim(), label: (status.Description || '').trim() };
            });

            return Object.assign({}, state, {
                berryTypes: action.payload,
                berryTypeOptions
            });
        case SET_OVERRIDE_DATA:
            action.payload.map((item) => {
                return Object.assign(item, {
                    startDateString: DuDateUtilities.FormatDateFromIso(item.StartDate),
                    endDateString: DuDateUtilities.FormatDateFromIso(item.EndDate)
                });
            });
            return { ...state, ranchOverrideData: action.payload };

        case SAVE_FETCHED_RANCH_DATA:
            let aFetchedRanchData = [...state.fetchedRanchData];
            let payload = action.payload;
            aFetchedRanchData.push({ RanchNbr: payload.RanchNbr, Name: payload.Name });
            return { ...state, fetchedRanchData: aFetchedRanchData };

        case UPDATE_SAVE_ENABLED:
            return { ...state, updateSaveAction: action.payload };
        case SELECT_DISPLAY_TABS:
            return { ...state, tabs: action.payload };
        case SET_USER_GROUP:
            return { ...state, userGroup: action.payload };
        case SET_LOGGED_IN_USER:
            return { ...state, loggedInUser: action.payload };
        default:
            return state;
    }
};

export default masterReducer;
