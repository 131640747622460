export const SET_ERRORS = 'SET_ERRORS';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const LOGOUT = 'LOGOUT';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const SET_PAGE_DIRTY = 'SET_PAGE_DIRTY';
export const setPageDirty = (isPageDirty) => ({
    type: SET_PAGE_DIRTY,
    payload: isPageDirty
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

// Modal Reducers
export const setErrorsAction = (title, errors) => ({
    type: SET_ERRORS,
    payload: { title, errors }
});

export const hideErrorDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});

export const showLoadingScreenAction = (title) => ({
    type: SHOW_LOADING_SCREEN,
    payload: {title}
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});
