import { Middleware } from 'one-ring';

const locationBypMapping = [
    Middleware.CreateMapping('WarehouseNbr', 'WarehouseNbr'),
    Middleware.CreateMapping('Bypass', 'Bypass'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('BypassWH', 'BypassWH'),
    Middleware.CreateMapping('Percentage', 'Percentage'),
    Middleware.CreateMapping('UpdatedBy', 'UpdatedBy'),
    Middleware.CreateMapping('UpdatedDateTime', 'UpdatedDateTime'),
    Middleware.CreateArrayMapping('Results')
];

function ConfigureMiddleWare() {
    if (!Middleware.CheckMappingExists('override', 'GET')) {
        Middleware.RegisterMapping('override', 'GET', locationBypMapping);
        Middleware.RegisterMapping('override', 'POST', locationBypMapping);
    }
}

const locationBypassMappingTypesGroup = {
    ConfigureMiddleWare
};

export default locationBypassMappingTypesGroup;
