import { PRODUCING_AREA, RANCH_DETAILS, SAVED_RANCH_DETAILS, USER_PRODUCING_AREA } from '../Constant/ActionConstant';

const initialState = {
    producingArea: [],
    ranchDetails: [],
    ranchDetailOptions: [],
    userProducingArea: []
};

const overrideReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCING_AREA:
            let producingArea = action.payload.map((item) => {
                return { value: item.Code, label: item.Code + '-' + item.Descript };
            });
            return { ...state, producingArea: producingArea };
        case RANCH_DETAILS:
            let data = action.payload.data;
            if (data && data.length) {
                let ranchDetailOptions = data.map((item) => {
                    return { value: item.Name, label: item.RanchNbr };
                });

                let ranchDetails = [...state.ranchDetails];
                ranchDetails.push({
                    [action.payload.producingArea]: ranchDetailOptions
                });

                return { ...state, ranchDetailOptions: ranchDetailOptions, ranchDetails: ranchDetails };
            } else {
                return { ...state, ranchDetailOptions: [] };
            }
        case SAVED_RANCH_DETAILS:
            return { ...state, ranchDetailOptions: action.payload };

        case USER_PRODUCING_AREA:
            let userProducingArea = action.payload.map((item) => {
                return { value: item.ProducingArea, label: item.ProducingAreaCode };
            });
            return { ...state, userProducingArea };
        default:
            return state;
    }
};

export default overrideReducer;
