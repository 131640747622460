import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/styles/withStyles';
import { withAuth } from '@okta/okta-react';
import { DrcButton, DrcInput, DrcSelect } from 'driscolls-react-components';
import DrcDateRangePicker from './DrcDateRangePicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import APIEndPoints from '../../services/api';
import {
    setBerryType,
    setVariety,
    lookUpValues,
    getPointMatrixPreviewData,
    resetSelectedIndex,
    setLabel,
    setEstimatedGroup,
    setDistrictGroup
} from '../../actions/PointMatrixAction';
import { Middleware } from 'one-ring';
import {
    DISTRICT_GROUP,
    DISTRICT_GROUP_PLACEHOLDER,
    ESTIMATE_GROUP,
    ESTIMATE_GROUP_PLACEHOLDER,
    PRODUCT_ATTRIBUTE,
    LABEL,
    LABEL_PLACEHOLDER,
    VARIETY,
    POOL_WEEK_RANGE,
    POOL_WEEK_RANGE_PLACEHOLDER,
    MATRIX_SEARCH,
    VARIETY_PLACEHOLDER,
    STATUS
} from '../../language/english';
import { getFormattedDateValues } from '../../util/utils';
import { Status } from '../../Constant/StatusConstant';
import BerryTypeSelect from '../BerryTypeSelect';

let styles = (theme) => ({
    dialog: {
        '& .MuiToolbar-root': {
            display: 'none'
        }
    },
    datepickerToolbar: {
        '& .MuiDialogActions-root': {
            display: 'none'
        }
    }
});
const PAGE_TYPE = 'Lookup';
const PointMatrixPreviewHeader = (props) => {
    const [pointMatrix, setPointMatrix] = useState({
        districtGroup: '',
        estimatedGroup: '',
        label: '',
        berryType: '',
        productAttribute: 'Brix',
        variety: props.previewVariety[0],
        startDate: new Date(),
        endDate: new Date(),
        poolWeek: '',
        status: Status[1],
        minPoint: '',
        maxPoint: '',
        spread: '',
        isDefault: true,
        helperText: {}
    });

    const refContainer = useRef(null);

    useEffect(() => {
        props.lookUpValues([]);
        props.getPointMatrixPreviewData([]);
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('label', token, APIEndPoints.GET_LABEL, 'GET', { Type: 'label' }, null).then((res) => {
                if (res.Data.length) {
                    props.setLabel(res.Data);
                }
            });
            if (!props.estimateGroup.length)
                Middleware.Send('estimatedGroup', token, APIEndPoints.QUARTILE_ESTIMATED_GROUP, 'GET', { Type: 'estimatedGroup' }, null).then(
                    (res) => {
                        if (res.Data.length) {
                            props.setEstimatedGroup(res.Data);
                        }
                    }
                );
            if (!props.districtGroup.length)
                Middleware.Send('districtGroups', token, APIEndPoints.DISTRICT_GROUPS, 'GET', { Type: 'districtGroups' }, null).then((res) => {
                    if (res.Data.length) {
                        props.setDistrictGroup(res.Data);
                    }
                });
        });
    }, []);

    const getVariety = (producingArea, berryType) => {
        if (producingArea && berryType) {
            props.auth.getAccessToken().then((token) => {
                Middleware.Send(
                    'variety',
                    token,
                    APIEndPoints.GET_VARIETY(berryType, producingArea),
                    'GET',
                    { Type: 'variety' },
                    { overrideResponseMapping: true, loadingMessage: 'Fetching variety...' }
                ).then((res) => {
                    if (res.Data.length) {
                        props.setVariety(res.Data);
                    } else {
                        props.setVariety([]);
                    }
                });
            });
        }
    };
    const handleDistrictGroupChange = (option) => {
        if (option) {
            setPointMatrix({ ...pointMatrix, districtGroup: option, variety: props.previewVariety[0] });
            getVariety(option.value, pointMatrix.berryType.label);
        }
    };

    const handleBerryChange = (option) => {
        if (option) {
            setPointMatrix({ ...pointMatrix, berryType: option, variety: props.previewVariety[0] });
            getVariety(pointMatrix.districtGroup.value, option.label);
        }
    };

    const handleLabelChange = (option) => {
        setPointMatrix({ ...pointMatrix, label: option });
    };

    const handleVarietyChange = (option) => {
        if (option) {
            setPointMatrix({ ...pointMatrix, variety: option });
        } else {
            setPointMatrix({ ...pointMatrix, variety: props.previewVariety[0] });
        }
    };

    const handleDateRangeChange = (startDate, endDate) => {
        setPointMatrix({ ...pointMatrix, startDate: startDate, endDate: endDate, isDefault: false });
    };

    const handleStatusChange = (status) => {
        setPointMatrix({ ...pointMatrix, status });
    };

    const handleReset = (startDate, endDate) => {
        setPointMatrix({ ...pointMatrix, startDate: startDate, endDate: endDate, isDefault: true });
    };

    const handleClose = (event) => {
        refContainer.current.handleResetSelection();
        setPointMatrix({ ...pointMatrix, isDefault: true, hideDateDisplay: true });
    };

    const handleSearch = () => {
        let oValues = getFormattedDateValues(pointMatrix);
        if (oValues.varietyDescription === 'All') {
            oValues.varietyCode = '';
        }
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_POINT_MATRIX_DATA(
                    pointMatrix.districtGroup.label,
                    pointMatrix.estimatedGroup.label,
                    'Brix',
                    pointMatrix.label.value,
                    oValues.startDate,
                    oValues.endDate,
                    oValues.varietyCode,
                    pointMatrix.berryType.label,
                    oValues.varietyDescription,
                    pointMatrix.status.label
                ),
                'GET',
                { Type: PAGE_TYPE },
                { overrideResponseMapping: true, loadingMessage: 'Searching point matrix...' }
            ).then((res) => {
                props.resetSelectedIndex(true);

                if (res && res.length) {
                    props.getPointMatrixPreviewData(res);
                    props.handleSearch(true);
                } else {
                    props.getPointMatrixPreviewData([]);
                    props.lookUpValues([]);
                    props.handleSearch(false);
                }
            });
        });
    };
    const handleEstimatedGroupChange = (option) => {
        setPointMatrix({ ...pointMatrix, estimatedGroup: option });
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid container spacing={3} justify="space-between" alignItems="flex-start">
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcSelect
                            required
                            name="districtgroup"
                            label={DISTRICT_GROUP}
                            value={pointMatrix.districtGroup}
                            onChange={handleDistrictGroupChange}
                            options={props.districtGroup}
                            placeholder={DISTRICT_GROUP_PLACEHOLDER}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcSelect
                            required
                            name="Label"
                            label={LABEL}
                            value={pointMatrix.label}
                            onChange={handleLabelChange}
                            options={props.label}
                            placeholder={LABEL_PLACEHOLDER}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <BerryTypeSelect
                            required
                            value={pointMatrix.berryType}
                            onChange={handleBerryChange}
                            options={props.berryType}
                        ></BerryTypeSelect>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcSelect
                            required
                            name={ESTIMATE_GROUP}
                            label={ESTIMATE_GROUP}
                            value={pointMatrix.estimatedGroup}
                            onChange={handleEstimatedGroupChange}
                            options={props.estimateGroup}
                            placeholder={ESTIMATE_GROUP_PLACEHOLDER}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcInput
                            label={PRODUCT_ATTRIBUTE}
                            value={pointMatrix.productAttribute}
                            InputLabelProps={{ shrink: true }}
                            name="ProductAttribute"
                            readOnly={true}
                        ></DrcInput>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcSelect
                            name="Variety"
                            label={VARIETY}
                            value={pointMatrix.variety}
                            onChange={handleVarietyChange}
                            isDisabled={!pointMatrix.berryType || !pointMatrix.districtGroup}
                            options={props.previewVariety}
                            placeholder={VARIETY_PLACEHOLDER}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcDateRangePicker
                            ref={refContainer}
                            style={{ color: '#563e70' }}
                            label={POOL_WEEK_RANGE}
                            name="PoolWeek"
                            color="#6f5091"
                            onChange={handleDateRangeChange}
                            onReset={handleReset}
                            months={2}
                            direction="horizontal"
                            placeholder={POOL_WEEK_RANGE_PLACEHOLDER}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            weekDisplay={true}
                            startDate={pointMatrix.startDate}
                            endDate={pointMatrix.endDate}
                            defaultValue={pointMatrix.isDefault}
                            onClose={handleClose}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <DrcSelect name="Status" label={STATUS} value={pointMatrix.status} onChange={handleStatusChange} options={Status} />
                        <DrcButton
                            floatRight
                            isPrimary
                            onClick={handleSearch}
                            disabled={!pointMatrix.districtGroup || !pointMatrix.berryType || !pointMatrix.label || !pointMatrix.estimatedGroup}
                        >
                            {MATRIX_SEARCH}
                        </DrcButton>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        estimateGroup: state.quartileReducer.estimateGroup,
        districtGroup: state.pointMatrixReducer.districtGroup || [],
        berryType: state.pointMatrixReducer.berryType,
        label: state.pointMatrixReducer.label,
        variety: state.pointMatrixReducer.variety,
        previewVariety: state.pointMatrixReducer.previewVariety,
        lookUp: state.pointMatrixReducer.lookUp,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
};

export default connect(mapStateToProps, {
    setBerryType,
    setVariety,
    lookUpValues,
    getPointMatrixPreviewData,
    resetSelectedIndex,
    setLabel,
    setEstimatedGroup,
    setDistrictGroup
})(withRouter(withAuth(withStyles(styles)(PointMatrixPreviewHeader))));
